<template>
  <div class="notice-container">
    <el-row>
      <el-col class="notice-left" :span="17">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div>公告</div>
            <div>></div>
            <div class="last-text">公告详情</div>
          </div>
          <div class="notice-title">开展“十四五”规划——里德奋勇争先</div>
          <div class="notice-time">
            <div>发布时间： 2021/01/22 11:18:13</div>
            <div>浏览： 89次</div>
          </div>
          <p>
            2021年1月21日下午，由县委书记方毅，副县长王先勇，县委常委、管委会书记施建华，管委会党委副书记、主任周跃群带队桐庐县发改局、县经信局、县科技局、县财政局、县商务局主要负责人在徐龙忠总经理，李云星副总经理的陪同下参观了公司，并下到车间对我们的工作进行了调研和指导。
          </p>
          <div class="notice-img-1">
            <img src="./1.png" srcset="./1@2x.png 2x" alt="">
          </div>
          <p>
            安防监控、无线支付、储能锂电管理系统是未来的大势所趋，社会发展离不开高新技术，里德作为国家高新技术的龙头企业受到县委政府的高度重视。县委方书记指出，2020年里德紧紧围绕县委、县政府决策部署，按照“奋进2020”目标不降、任务不减、决心不变的要求，在充分带动桐庐发展，解决外来人员务工等方面做的不错，同时县委书记对我们里德家人辛勤的付出表示了诚挚的慰问。
          </p>
          <div class="notice-img-2">
            <img src="./2.png" srcset="./2.png,./2@2x.png 2x" alt="">
          </div>
          <p>
            “天下古今之才人，皆以一傲字致败；天下古今之庸人，皆以一惰字致败。”
            方毅书记以曾国藩家训中“六戒五勤”鼓励我们里德在新的一年继续加压奋进、破难攻坚，勇挑经济重担，为奋斗美丽新元年作出更大贡献。
          </p>
          <div class="notice-img-3">
            <img src="./3.png" srcset="./3.png,./3@2x.png 2x" alt="">
            <img src="./4.png" srcset="./4.png,./4@2x.png 2x" alt="">
          </div>
          <p>
            最后根据目前疫情防控形式，施常委鼓励大家春节留桐，政府积极为园区外地员工制定补助政策，全力减少人员流动，希望大家都能为疫情防控付出自己的一份力。
          </p>
          <div class="notice-footer">
            <!-- <div class="notice-prev">< 上一篇</div> -->
            <div class="notice-prev">
              <router-link tag="span" to="/notice4">
                < 上一篇</router-link>
            </div>
            <div class="notice-next">
              <router-link tag="span" to="/notice2">下一篇 ></router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col class="notice-right" :span="7">
        <div class="content">
          <div class="title">最新公告</div>
          <div>
            <div class="notice-other-1 pointer">
              <router-link tag="span" to="/notice14">童年，是一只载着无数快乐的小船；童年……</router-link>
            </div>
            <div class="notice-other-time-1">2022-06-01 15:26</div>

            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice13">金牛奋蹄奔大道 玉虎添翼舞新春。我们告……</router-link>
            </div>
            <div class="notice-other-time-2">2021-12-31 10:36</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice12">金牛奋蹄奔大道 玉虎添翼舞新春。我们告……</router-link>
            </div>
            <div class="notice-other-time-2">2021-12-31 10:36</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice11">金牛奋蹄奔大道 玉虎添翼舞新春。我们告……</router-link>
            </div>
            <div class="notice-other-time-2">2021-12-31 10:36</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice10">金牛奋蹄奔大道 玉虎添翼舞新春。我们告……</router-link>
            </div>
            <div class="notice-other-time-2">2021-12-31 10:36</div>

          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.notice-container {
  padding: 24px gap() 56px;
}

.notice-left {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 16px 32px 40px;
    font-size: 16px;
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-time {
  display: flex;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    margin-right: 32px;
  }
}

.notice-img-1,
.notice-img-2 {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.notice-img-3 {
  img {
    display: block;
    margin: auto;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 64px;
  border-top: 1px solid #d4d4d4;
}

.notice-prev {
  color: #da261d;
  cursor: pointer;
}

.notice-next {
  color: #da261d;
  cursor: pointer;
}

.notice-right {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 24px 24px 400px;
    box-sizing: border-box;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
}

.notice-other-time-1,
.notice-other-time-2 {
  padding: 8px 0;
  color: #a6a6a6;
  border-bottom: 1px solid #d4d4d4;
}

.notice-other-2 {
  margin-top: 32px;
}
</style>
